import { getLocalStorage, setLocalStorage } from '../utils/localStorage';
import {
  GithubWhiteIcon,
  GoogleWhiteIcon,
  WindowsWhiteLogo,
} from '../images/shapes';
import Loader from '../components/Loader/Loader';
import TagManager from 'react-gtm-module';
import { Navigate } from 'react-router-dom';
import { getMediaUrl } from './flotiq-client/api-helpers';

// :: Images
import image1 from '../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-1-placeholder.jpg';
import image2 from '../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-2-placeholder.jpg';
import image3 from '../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-3-placeholder.jpg';
import image4 from '../images/graphics/type-definition-card-backgrounds-placeholders/card_bg-4-placeholder.jpg';

const ctdFeaturedImages = [image1, image2, image3, image4];

const apiUrl = process.env.REACT_APP_FLOTIQ_API_URL;

/**
 * Authentication buttons array
 * @type {Array.<{name: string, logo: React.ReactNode, url: string}>} array of logo and url shape
 */
export const authButtonsData = [
  {
    name: 'Microsoft',
    logo: <WindowsWhiteLogo className="h-full w-full" />,
    url: `${apiUrl}/connect/azure`,
  },
  {
    name: 'GitHub',
    logo: <GithubWhiteIcon className="h-full w-full" />,
    url: `${apiUrl}/connect/github`,
  },
  {
    name: 'Google',
    logo: <GoogleWhiteIcon className="h-full w-full" />,
    url: `${apiUrl}/connect/google`,
  },
];

/**
 * Get login error text from status and activated search params
 * @param {string} status
 * @param {(key: string, options?: object) => string} t Translation function
 * @param {string} [activated='']
 * @returns {string} error message
 */
export const getLoginErrorText = (status, t, activated = '') => {
  if (activated === 'false') return t('Activation.False');
  else if (status === 'bad_verification_code')
    return t('Login.VerificationCode');
  else if (status === 'user_disabled') return t('Login.UserDisabled');
  else if (status === 'azure_email_not_available')
    return t('Login.AzureNotAvailable');
  else if (status && status !== 'success') return t('Login.DefaultError');
  return '';
};

/**
 * Generate object with test id props if testId is defined
 * @param {string} testId
 * @param {string} [suffix]
 * @param {string} [testIdPropName='data-testid']
 * @returns {object} object containing test props
 */
export const getTestProps = (
  testId,
  suffix,
  testIdPropName = 'data-testid',
) => {
  if (!testId) return {};
  if (!suffix) {
    return { [testIdPropName]: testId };
  }
  return {
    [testIdPropName]: `${testId}-${suffix}`,
  };
};

/**
 * Read key value deep inside object
 * @param {string} key
 * @param {object} object
 * @returns {*} example: read 'object[0].key' from 'object: [{key: value}]
 */
export const deepReadKeyValue = (key, object) => {
  return key
    .split(/[[.\]]/)
    .filter((kp) => !!kp)
    .reduce((nestedOptions, keyPart) => {
      return nestedOptions?.[keyPart];
    }, object);
};

/**
 * insert value into object under path referred by key
 * @param key e.g. subObject.subArray[0]
 * @param value
 * @param options target to insert into
 * @returns
 */
export const deepAssignKeyValue = (key, value, options) =>
  key
    .split(/[[.\]]/)
    .filter((kp) => !!kp)
    .reduce((nestedOptions, keyPart, index, keysArray) => {
      if (!nestedOptions[keyPart]) {
        const isArray =
          index < keysArray.length - 1 && /^\d+$/.test(keysArray[index + 1]);

        if (!isArray) {
          nestedOptions[keyPart] = {};
        } else {
          nestedOptions[keyPart] = [];
        }
      }
      if (index >= keysArray.length - 1) {
        if (value === undefined) delete nestedOptions[keyPart];
        else nestedOptions[keyPart] = value;
      }
      return nestedOptions[keyPart];
    }, options);

/**
 * Get tag url from tag id
 * @param {string} tagId
 * @returns {string} tag url
 */
export const getTagUrl = (tagId) => {
  if (!tagId) {
    return '';
  }
  return `/api/v1/content/_tag/${tagId}`;
};

/**
 * Convert size in bytes into displayable format
 * @param {number} bytes
 * @param {number} [precision=1]
 * @param {array} [unitsPattern=['B', 'kB', 'MB', 'GB', 'TB', 'PB']]
 * @returns {string} formatted data size value with b, kB, MB etc units
 */
export const getDataSize = (
  bytes,
  precision = 1,
  unitsPattern = ['B', 'kB', 'MB', 'GB', 'TB', 'PB'],
) => {
  if (isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '-';
  let units = unitsPattern,
    number = Math.floor(Math.log(bytes) / Math.log(1000));
  return (
    (bytes / Math.pow(1000, number)).toFixed(precision) + ' ' + units[number]
  );
};

/**
 * Generate url to API docs for given user
 * @param {object} userDetails details with apiToken and organization
 * @param {object} apiKeyObject apiKey object
 * @returns {string} user's API docs url
 */
export const generateApiDocs = (userDetails, apiKeyObject) => {
  if (!process.env.REACT_APP_API_DOC_GENERATOR_URL) return '';
  const organizationId = userDetails?.organization;
  const token = window.btoa(`${apiKeyObject?.apiKey}@${organizationId}`);
  const params = new URLSearchParams({
    token,
    api: `${process.env.REACT_APP_FLOTIQ_API_URL}/api`,
  });
  return `${process.env.REACT_APP_API_DOC_GENERATOR_URL}?${params.toString()}`;
};

/**
 * Generate url to export users into CSV
 * @param {string} jwt users jwt token
 * @param queryParams
 * @returns {string} url to export users into CSV
 */
export const generateExportUsersCsvUrl = (jwt, queryParams = {}) => {
  const params = new URLSearchParams({
    start: 0,
    limit: 0,
    format: 'csv',
    Authorization: jwt,
    ...queryParams,
  });

  return `${
    process.env.REACT_APP_FLOTIQ_API_URL
  }/api/users-data-preview/export?${params.toString()}`;
};

/**
 * Generate url to export spaces into CSV
 * @param {string} jwt users jwt token
 * @returns {string} url to export spaces into CSV
 */
export const generateExportSpacesCsvUrl = (jwt, queryParams = {}) => {
  const params = new URLSearchParams({
    start: 0,
    limit: 0,
    format: 'csv',
    Authorization: jwt,
    ...queryParams,
  });

  return `${
    process.env.REACT_APP_FLOTIQ_API_URL
  }/api/spaces-data-preview/export?${params.toString()}`;
};

/**
 * Format error message to string
 * @param {string|array|object} error
 * @returns {string} formatted string error
 */
export const formatErrorToString = (error) => {
  if (typeof error === 'string') return error;
  if (Array.isArray(error))
    return error
      .filter((element) => element)
      .map((element) => formatErrorToString(element))
      .join(', ');
  return Object.keys(error)
    .filter((key) => error[key])
    .map((key) => {
      const messageKey = key ? `${key}: ` : '';
      return `${messageKey}${formatErrorToString(error[key])}`;
    })
    .join(', ');
};

/**
 * Generate checksum for given strings
 * @param {string[]} args
 * @returns {number} checksum
 */
export const strToChecksum = (...args) => {
  let hash = 0,
    i,
    chr,
    len;
  const text = args.join('-');
  if (text.length === 0) return hash;
  for (i = 0, len = text.length; i < len; i++) {
    chr = text.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

/**
 * Get initial columns options based on table width
 * @param {number} [availableWidth=0]
 * @param {array} [columns=[]]
 * @param {number} [minWidth=50]
 * @param {boolean} [calculateWithSelectionColumn=true]
 * boolean for skipping selection column size in columns with calculations
 * @param {object} [contentType] full CTD
 * @returns {{options: object[], cols: object[]}} object containing default options for columns
 */
export const getInitialColumnsOptions = (
  availableWidth = 0,
  columns = [],
  minWidth = 50,
  contentType = null,
) => {
  const widthMinColumn = minWidth;
  let widthColumn = 50;
  const options = [];
  const cols = [];

  /**
   * First pass - determine columns visibility
   */
  if (columns.length > 0) {
    for (let columnItemKey in columns) {
      /**
       * If field is a normal field:
       *  - return value of `hidden` attribute
       *  - if not `hidden` attribute is defined - mark as visible
       * if field is one of internal fields:
       *  - mark hidden by default
       *  - if showByDefault property is set - use it's value
       */
      let isHidden = null;
      if (
        typeof contentType?.metaDefinition?.propertiesConfig[
          columns[columnItemKey].accessor
        ] !== 'undefined'
      ) {
        isHidden =
          typeof contentType?.metaDefinition?.propertiesConfig[
            columns[columnItemKey].accessor
          ]?.hidden !== 'undefined'
            ? contentType.metaDefinition?.propertiesConfig[
                columns[columnItemKey].accessor
              ].hidden
            : false;
      } else if (columns[columnItemKey].accessor.indexOf('internal.') === 0) {
        isHidden =
          typeof columns[columnItemKey].showByDefault !== 'undefined'
            ? !columns[columnItemKey].showByDefault
            : true;
      } else if (contentType && columns[columnItemKey].accessor === 'id') {
        isHidden = true;
      } else {
        isHidden = false;
      }
      columns[columnItemKey].hide = isHidden;
    }
  }

  /**
   * Second pass - determine visible columns widths
   */

  let columsCounter = columns.length;

  let widthToSubtract = 0;

  if (columns.length > 0) {
    columns.forEach((column) => {
      if (column.hide) {
        columsCounter -= 1;
      } else if (column.baseWidth) {
        widthToSubtract += column.baseWidth;
        columsCounter -= 1;
      }
    });
  }

  const tableSpareWidth = availableWidth - widthToSubtract;

  if (tableSpareWidth > 0 && columsCounter) {
    const calculateColumnWidth = Number(
      (tableSpareWidth / columsCounter).toFixed(0),
    );
    widthColumn =
      calculateColumnWidth >= widthMinColumn
        ? calculateColumnWidth
        : widthMinColumn;
  }

  if (columns.length > 0) {
    for (let columnItemKey in columns) {
      const columnWidth = columns[columnItemKey].baseWidth || widthColumn;

      cols.push({
        ...columns[columnItemKey],
        width: columnWidth,
      });

      options.push({
        width: columnWidth,
        colId: columns[columnItemKey].accessor,
        hide: columns[columnItemKey].hide,
      });
    }
  }

  return { options, cols };
};

/**
 * Set initial grid options
 * @param {array} cols array of current columns
 * @param {number} minWidth min width of each columns
 * @param {ref} gridRef reference to grid with data
 * @param {func} setGridOptions function to set new grid options
 * @param {func} setColumns function to set new columns
 * @param {string} gridLocalStorageKey key to local storage grid options
 * @param {boolean} [calculateWithSelectionColumn=true]
 * @param {object} contentType full CTD
 * boolean for skipping selection column size in columns with calculations
 */
export const setInitialGridOptions = (
  cols,
  minWidth,
  availableWidth,
  setGridOptions,
  setColumns,
  gridLocalStorageKey,
  contentType,
) => {
  const initGridOptions = getInitialColumnsOptions(
    availableWidth,
    cols,
    minWidth,
    contentType,
  );
  setGridOptions(initGridOptions.options);
  setColumns?.(initGridOptions.cols);
  if (gridLocalStorageKey) {
    setLocalStorage(gridLocalStorageKey, initGridOptions.options);
  }
};

/**
 * Set initial grid options
 * @param {array} order current order of columns
 * @param {string} gridLocalStorageKey key to local storage grid options
 * @param {func} setGridOptions function to set new grid options
 */
export const updateLocalStorageGridOptions = (
  order,
  gridLocalStorageKey,
  setGridOptions,
) => {
  const currentLocalGridOption = getLocalStorage(gridLocalStorageKey, true);
  const newOptions = [];
  const validOptions = [];
  let shouldUpdate = false;

  // Add new grid options
  for (let item in order) {
    const indexInLocal = currentLocalGridOption.findIndex(
      (el) => el.colId === order[item],
    );
    if (indexInLocal < 0) {
      newOptions.push({
        width: 100,
        colId: order[item],
        hide: false,
      });
      shouldUpdate = true;
    }
  }

  // Validate saved local storage based on provided keys from order
  for (let option in currentLocalGridOption) {
    const optionID = currentLocalGridOption[option].colId;
    if (optionID === 'id' || optionID === 'action') {
      validOptions.push(currentLocalGridOption[option]);
    } else {
      const indexInOrder = order.findIndex((el) => el === optionID);

      if (indexInOrder > -1) {
        validOptions.push(currentLocalGridOption[option]);
      } else {
        // Options was in saved but is no longer in order
        // Will be removed and need to be updated base on new validOptions
        shouldUpdate = true;
      }
    }
  }

  if (shouldUpdate) {
    const updateOptions = [...validOptions, ...newOptions];
    setLocalStorage(gridLocalStorageKey, updateOptions);
    setGridOptions(updateOptions);
  }
};

/**
 * Function to resize columns
 * @param {number} columnWidth width of changed column
 * @param {string} dataKey key of changed column
 * @param {array} gridOptions current grid options
 * @param {func} setGridOptions function to set new grid options
 * @param {string} gridLocalStorageKey key to local storage grid options
 */
export const resizeColumn = (
  columnWidth,
  dataKey,
  gridOptions,
  setGridOptions,
  gridLocalStorageKey,
) => {
  // Case: find column index
  const columnIndex = gridOptions?.findIndex((object) => {
    return object['colId'] === dataKey;
  });

  if (columnIndex > -1) {
    const newOptions = [...gridOptions];
    newOptions[columnIndex].width = columnWidth;

    if (gridLocalStorageKey) {
      setLocalStorage(gridLocalStorageKey, newOptions);
    }

    if (setGridOptions) {
      setGridOptions(newOptions);
    } else {
      return newOptions;
    }
  }
};

/**
 * Find errors for property setting
 * @param {string} name
 * @param {object} propertyErrors
 * @param {object} formik
 * @returns {string|array|object} property errors
 */
export const findErrors = (name, propertyErrors, formik) => {
  const errorsFromFormik = deepReadKeyValue(name, formik.touched)
    ? deepReadKeyValue(name, formik.errors)
    : null;
  const errors = deepReadKeyValue(name, propertyErrors) || errorsFromFormik;
  return errors;
};

/**
 * Function to handle multiple error messages on delete with relations issue columns
 * @param {string} message string with errors separated by coma
 * @returns {string} error message
 */
export const validateErrorMessage = (message) => {
  // Case: handle mutliple errors
  if (message.split(',').length > 1) {
    const currentErrors = message.split(',');
    const currentMessages = [];
    const messagesUsedInAnother = [];

    // Error Case: another content object
    for (let errorItem in currentErrors) {
      if (
        currentErrors[errorItem].indexOf('is used in another content object') >
        -1
      ) {
        messagesUsedInAnother.push(
          currentErrors[errorItem]
            .replace('Content object:', '')
            .replace('is used in another content object.', ''),
        );
      } else {
        currentMessages.push(currentErrors[errorItem]);
      }
    }
    if (messagesUsedInAnother.length) {
      currentMessages.push(
        'Content objects are used in another content objects:' +
          messagesUsedInAnother.join(','),
      );
    }
    message = currentMessages.join(',');
  }
  return message;
};

/**
 * Function to get initial value for type.
 * Due to formik touched state for arrays it has to be the function.
 * Formik is setting touched state based on initial values,
 * so it has to be different reference for arrays.
 * @param {string} type of the field
 * @returns {string|Array|Boolean|Number|object} default value
 */
export const getDefaultValueForType = (type) => {
  const typeDefaults = {
    string: '',
    number: 0,
    boolean: false,
    array: [],
    object: {},
  };
  return type in typeDefaults ? typeDefaults[type] : '';
};

/**
 * Calculate content object title based on object itself and definition field order
 * @param {*} object
 * @param {{metaDefinition: {order: string[]}}} contentType
 * @returns {string} content object title
 */
export const getObjectTitle = (object, contentType = null) => {
  if (!object) return '';

  if (object.internal.objectTitle) return object.internal.objectTitle;

  if (contentType?.metaDefinition?.order) {
    const def = contentType.metaDefinition;
    const firstTextField = def.order.find(
      (field) => def.propertiesConfig[field].inputType === 'text',
    );

    if (firstTextField && object[firstTextField]) return object[firstTextField];
  }

  const assumedTitleFields = ['title', 'name', 'fileName'];

  for (let field of assumedTitleFields) {
    if (typeof object?.[field] === 'string') return object?.[field];
  }

  return object?.id;
};

/**
 * Generate full redirect url, based on PUBLIC_URL env variable
 */
export const generateRedirectUrl = (path = '', encodeUrl = false) => {
  const publicUrl = process.env.PUBLIC_URL || window.location.origin;
  const redirectUri = `${publicUrl.replace(/\/{0,10}$/, '')}/${path.replace(
    /^\/{0,10}/,
    '',
  )}`;

  if (!encodeUrl) return redirectUri;

  return encodeURIComponent(redirectUri);
};

/**
 * Capitalize first letter of type string text
 */
export const capitalizeText = (text) => {
  if (text && typeof text === 'string') {
    return text.charAt(0).toUpperCase() + text.slice(1);
  } else {
    return text;
  }
};

/**
 * Truncate text with length
 */
export const getTruncateOnLengthText = (value, truncate, truncateLength) => {
  let currentValue = value;

  if (typeof currentValue !== 'string' && value) {
    currentValue = value.toString();
  }

  if (
    truncate &&
    truncateLength &&
    currentValue &&
    currentValue.length > truncateLength
  ) {
    return `${currentValue.slice(0, truncateLength)} ...`;
  } else {
    return value;
  }
};

/**
 * Module enable from env
 */
export const isModuleEnabled = (key) => {
  const moduleEnabledEnv = process.env[`REACT_APP_MODULE_${key}`];
  if (moduleEnabledEnv) {
    try {
      return JSON.parse(moduleEnabledEnv) || false;
    } catch (err) {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * Sorted by key
 */
export const getSortedBy = (data, key, direction = 'desc') => {
  if (!data || !Array.isArray(data)) return;
  if (!key) return data;

  return [...data]?.sort((a, b) => {
    const keyA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
    const keyB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];

    if (keyA > keyB) {
      return direction === 'desc' ? -1 : 1;
    }
    if (keyA < keyB) {
      return direction === 'desc' ? 1 : -1;
    }
    return 0;
  });
};

/**
 * Get updated workflow status based on 'latest versions', 'status' and 'post versions update'
 */
export const getUpdateInformationBlockStatus = (
  status,
  version,
  allowUpdate,
  latestVersion,
) => {
  if (allowUpdate && version && latestVersion !== version) {
    if (status === 'public') {
      return 'published';
    } else if (status === 'archive') {
      return 'draft';
    }
  }

  return status;
};

/**
 * Get sorted folders structure
 */
export const getFoldersStructure = (data, currentFolderName, allowFolders) => {
  const currentData = [];
  const foldersData = [];
  const folderID = {};

  for (let dataKey in data) {
    const itemLabel = data[dataKey].label;

    if (itemLabel?.indexOf('/') > -1) {
      if (allowFolders) {
        const folderName = itemLabel.slice(0, itemLabel.indexOf('/'));

        if (!folderID[folderName]) {
          folderID[folderName] = {
            id: `${folderName}-folder`,
            name: folderName,
            label: folderName,
            folder: true,
            children: [data[dataKey]],
          };
        } else {
          folderID[folderName].children.push(data[dataKey]);
        }
      } else {
        foldersData.push(data[dataKey]);
      }
    } else {
      currentData.push(data[dataKey]);
    }
  }

  if (allowFolders) {
    for (const [, value] of Object.entries(folderID)) {
      foldersData.push(value);
    }
  }

  if (currentFolderName && allowFolders) {
    return getSortedBy(folderID[currentFolderName]?.children, 'label', 'asc');
  } else {
    return [
      ...getSortedBy(foldersData, 'label', 'asc'),
      ...getSortedBy(currentData, 'label', 'asc'),
    ];
  }
};

/**
 * Get formated value size based on mode or value.
 * @param {string|number} value
 * @param {string} mode
 * @param {string} unlimitedText
 * @returns {string|number}
 */
export const getFormatedSize = (value, mode, unlimitedText) => {
  if (value == null) return null;

  // Case: unlimited
  if (value === -1) {
    return unlimitedText || 'unlimited';
  }

  // Case: mode [ compact-number | MB ]
  if (mode === 'compact-number') {
    return Intl.NumberFormat('en', {
      notation: 'compact',
      compactDisplay: 'short',
    }).format(value);
  } else if (mode === 'MB') {
    if (value === 0) return '0 MB';
    return getDataSize(value, 0, ['MB', 'GB', 'TB', 'PB']);
  }

  // Case: unformated
  return value;
};

/**
 * Calculate unique hash from string
 * @param inputString
 * @return {string}
 */
export const getHash = (inputString) => {
  if (!inputString || inputString === '') return '0';
  let hash = 0;
  for (let i = 0, len = inputString.length; i < len; i++) {
    let chr = inputString.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0;
  }
  return hash.toString();
};

/**
 * Based on browser need to check images to prevent show broken images eg. tiff
 * @param {string} extension
 * @returns {bool}
 */
export const isImagePreviewSupported = (extension) => {
  if (!extension) return false;

  const problematicExtensions = ['tif', 'tiff'];
  const agent = navigator?.userAgent?.toLowerCase();
  const otherBrowser = /chrome|android|criOS|fxios|edgios/i.test(agent);
  const isSafari = /safari/i.test(agent);

  // Case: tif,tiff visible on Safari browser
  if (problematicExtensions.includes(extension)) {
    if (!otherBrowser && isSafari) {
      return true;
    } else {
      return false;
    }
  }

  return true;
};

/**
 * Parse scoped keys docs limit
 * @param limit
 * @returns {boolean}
 */
export const parseScopedKeysDocs = (limit) => {
  if (typeof limit === 'boolean') {
    return limit;
  }

  return Boolean(parseInt(limit));
};

/**
 * Prepare priceId based on localisation
 * @param priceId
 * @param planName
 * @param countryRegion
 * @returns
 */
export const getPriceIdByLocalisation = (priceId, planName, countryRegion) => {
  switch (`${countryRegion}-${planName}`) {
    case 'IN-Basic': // India - Basic - monthly
      return process.env.REACT_APP_PLAN_PRICE_BASIC_MONTHLY_INDIA || priceId;
    case 'IN-Pro': // India - Pro - monthly
      return process.env.REACT_APP_PLAN_PRICE_PRO_MONTHLY_INDIA || priceId;
    default:
      return priceId;
  }
};

/**
 * Prepare plan subtitle based on localisation
 * @param visibleName
 * @param price
 * @param unit
 * @param countryRegion
 * @returns
 */
export const getPlanSubtitle = (visibleName, price, unit, countryRegion) => {
  if (
    (countryRegion === 'IN') & (visibleName === 'Basic') &&
    process.env.REACT_APP_PLAN_PRICE_BASIC_MONTHLY_INDIA
  ) {
    return `${visibleName} 100 ₹/mo`;
  }

  if (
    countryRegion === 'IN' &&
    visibleName === 'Pro' &&
    process.env.REACT_APP_PLAN_PRICE_PRO_MONTHLY_INDIA
  ) {
    return `${visibleName} 1000 ₹/mo`;
  }

  return `${visibleName} ${price} ${unit}`;
};

/**
 * Get organization name with only visible first 5 letter and every odd letter
 * @param {string} name organization name
 * @returns {string} masked organization name
 * @example
 * getMaskedOrganizationName('someOrganization@gmail.com')
 * // returns 'someO*g*n*z*t*o*@*m*i*.*o*'
 */
export const getMaskedOrganizationName = (name) => {
  if (!name) return '';
  return name
    .split('')
    .map((char, index) => {
      if (index < 5 || index % 2 === 0) return char;
      return '*';
    })
    .join('');
};

/**
 * Plugin Iframe Options
 */
export const getPluginsIframeOptions = (plugins, contentTypeName) =>
  plugins
    ?.filter(
      (plugin) =>
        Object.hasOwn(plugin, 'contentTypeDefinitions') &&
        (!plugin.contentTypeDefinitions ||
          plugin.contentTypeDefinitions.split(',').indexOf(contentTypeName) !==
            -1),
    )
    .map((plugin) => ({
      id: plugin.id,
      name: plugin.name,
      code: plugin.code?.replace(
        '__API_URL__',
        process.env.REACT_APP_FLOTIQ_API_URL,
      ),
      settings: plugin.settings?.reduce((currentSettings, plugin) => {
        currentSettings[plugin.key] = plugin.value;
        return currentSettings;
      }, {}),
    }));

/**
 * Get crop attributes dictionary from media transforms
 * @param {object} variant media variant data
 * @param {object} media media data for variant
 * @param {boolean} isInitialCrop if attributes are initial attributes for crop
 * @returns {{left: number, top: number, width: number, height: number}} crop attributes
 */
export const getCropAttributes = (variant, media, isInitialCrop = false) => {
  const cropAttributes = variant?.trim;

  const noCropValues =
    isInitialCrop && media
      ? {
          left: media.width / 4,
          top: media.height / 4,
          width: media.width / 2,
          height: media.height / 2,
        }
      : { left: 0, top: 0, width: media.width, height: media.height };

  return cropAttributes ? cropAttributes : noCropValues;
};

/**
 * Get default properties for image tags
 * @returns {{onDragStart: func}} onDragStart event for preventing dragging uploaded image
 */
export const getDefaultImageProperties = () => {
  return {
    onDragStart: (event) => {
      event.stopPropagation();
    },
  };
};

export const prepareColumns = (
  gridOptions,
  OPTIONS_KEY,
  editGrid,
  t,
  testId,
  setGridOptions,
  handleInitialGridOptions,
  setColumns,
  getDefinedColumns,
  isAllUsers,
  filterDatasourceFetch,
  isRoleAdmin = false,
) => {
  const cols = [];

  const gridOptionsByID = gridOptions?.reduce((acc, elem) => {
    acc[elem.colId] = elem;
    return acc;
  }, {});
  const currentLocalGridOption = getLocalStorage(OPTIONS_KEY, true);

  const hiddenColumnsById = {};
  const orderFromLocalStorage = [];

  for (let key in currentLocalGridOption) {
    hiddenColumnsById[currentLocalGridOption[key].colId] =
      currentLocalGridOption[key].hide;
    orderFromLocalStorage.push(currentLocalGridOption[key].colId);
  }

  const definedColumns = getDefinedColumns(
    gridOptionsByID,
    hiddenColumnsById,
    editGrid,
    t,
    testId,
    isAllUsers,
    filterDatasourceFetch,
    isRoleAdmin,
  );

  let order = Object.keys(definedColumns);
  order = [...new Set([...(orderFromLocalStorage || []), ...order])];

  if (orderFromLocalStorage.length) {
    updateLocalStorageGridOptions(order, OPTIONS_KEY, setGridOptions);
  }

  order.forEach((accessor) => {
    if (definedColumns[accessor]) cols.push(definedColumns[accessor]);
  });

  if (!gridOptions) {
    handleInitialGridOptions(cols, 250);
  } else {
    setColumns(cols);
  }
};

export const getStandardTopButtons = (
  t,
  isFormDisabled,
  testId,
  navigateOnSave,
  isSaving,
  actionMenuItems,
  id,
  handleDelete,
  isDeleting,
  link,
  form,
) => {
  const buttons = [];

  if (id) {
    buttons.push({
      key: 'delete',
      label: t('Global.Delete'),
      onClick: handleDelete,
      color: 'redBordered',
      iconImage: isDeleting ? <Loader size="small" type="spinner-grid" /> : '',
      disabled: isFormDisabled,
      ...getTestProps(testId, 'delete-type', 'testId'),
    });
  }
  buttons.push(
    ...[
      {
        key: 'cancel',
        label: t('Global.Cancel'),
        link: link,
        color: 'gray',
        disabled: isFormDisabled,
        ...getTestProps(testId, 'cancel-type', 'testId'),
      },
      {
        key: 'save',
        label: t('Global.Save'),
        onClick: () => {
          navigateOnSave.current = false;
        },
        iconImage: isSaving ? <Loader size="small" type="spinner-grid" /> : '',
        disabled: isFormDisabled,
        menuItems: actionMenuItems,
        type: 'submit',
        form: form,
        ...getTestProps(testId, 'save-type', 'testId'),
      },
    ],
  );
  return buttons;
};

/**
 * Get price or contact us text
 * @returns {string}
 */
export const getPriceOrContactUs = (
  price = 0,
  contactUsText = 'Contact us',
  interval = '',
  unit = '$',
) => {
  if (price === 0) return `${unit}${price}`;

  if (price > 0) {
    const validInterval = interval ? `/${interval}` : '';

    return `${unit}${price}${validInterval}`;
  }
  return contactUsText;
};

/**
 * Get color pattern based on plan name
 * @returns {string}
 */
export const getColorByPlanName = (name) => {
  switch (name) {
    case 'Free':
      return 'gray';
    case 'Basic':
      return 'orange';
    case 'Growth':
    case 'Pro':
      return 'blue';
    case 'Enterprise':
      return 'green';
    default:
      return 'gray';
  }
};

/**
 * Send event to GTM and remove event data from GTM dataLayer
 * @param {object} data Event data to send
 * @param {object} baseUserEventData Default user data for events
 */
export const sendEventAndClear = (data, baseUserEventData) => {
  TagManager.dataLayer({
    dataLayer: data,
  });

  window.dataLayer.push(function () {
    this.reset();
  });

  TagManager.dataLayer({
    dataLayer: baseUserEventData,
  });
};

export const handleRedirectUrl = (redirect_uri, response_type, user) => {
  const url = new URL(redirect_uri);

  if (response_type === 'code') {
    url.searchParams.append('api_key', user.data.apiReadOnlyToken);
  }

  if (url.host === window.location.host) {
    return <Navigate to={url.pathname + url.search} />;
  } else {
    url.hostname = 'localhost';
    window.location.replace(url.toString());
  }

  return null;
};

/**
 * Deep freeze the values of an object so that they cannot be changed. Changes will throw an error.
 * @param {object} object Object to freeze
 * @returns {object} Freezed object
 */
export const deepFreeze = (object) => {
  Object.values(object).forEach((value) => {
    if ((value && typeof value === 'object') || typeof value === 'function') {
      deepFreeze(value);
    }
  });

  return Object.freeze(object);
};

/**
 * Get URL to Stripe dashboard
 * @param {string} path A path relative to Stripe dashboard
 * @returns {string} Full URL to Stripe
 */
export const getStripeURL = (path) => {
  if (!process.env.REACT_APP_STRIPE_URL) return;

  return `${process.env.REACT_APP_STRIPE_URL.replace(
    /\/+$/,
    '',
  )}/${path.replace(/^\/*/, '')}`;
};

/**
 * Get featured image for content type
 * @param {string} contentTypeName The name of the content type
 * @param {array} featuredImage An array with the featured image data
 * @param {number} width The desired width of the image
 * @returns {string} Full URL to the image or default image
 */
export const getCtdFeaturedImage = (
  contentTypeName,
  featuredImage = [],
  width = 600,
) => {
  if (featuredImage && featuredImage.id) {
    const url = getMediaUrl(featuredImage, width);
    return url;
  }
  const defaultImage =
    ctdFeaturedImages[Math.abs(strToChecksum(contentTypeName) % 4)];
  return defaultImage;
};

/**
 * Generate tutorial path object
 * @param {object} object List of pages
 * @returns {object} Tutorial page id reference with number index
 */
export const generateTutorialIndexPath = (data) => {
  const res = {};

  for (let item in data) {
    const currentKey = data[item]?.page;
    if (currentKey && !res[currentKey]) {
      res[currentKey] = { index: 0 };
    }
  }
  return res;
};

/**
 * Check if user is ROLE_ADMIN
 * @param {object} user User local storage data
 * @returns {boolean} If user is role admin
 */
export const isUserRoleAdmin = (user) =>
  (user?.data?.roles || []).findIndex(
    (role) => 'ROLE_ADMIN'.indexOf(role) > -1,
  ) > -1 &&
  process.env.REACT_APP_ADMIN_ORGANIZATIONS.includes(user?.data?.organization);
